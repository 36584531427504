import {Injectable} from "@angular/core";
import {DoodbeastContract} from "../DoodbeastContract";
import web3 from 'web3';

@Injectable({
  providedIn: 'root',
})
export class MintFactory {

  public create(rawMintContract: any, address: string): DoodbeastContract {

    const saleMint = async (amount: number, totalEth: number) => {
      const wei = web3.utils.toWei(totalEth.toString(), 'ether');

      // console.log(`in factory: ${totalEth} wei: ${wei}`);

      const result = await rawMintContract.methods
        .saleMint(amount)
        .send({ from: address, value: wei });

      return result;
    };

    const freeMint = async (amount: number) => {
      const result = await rawMintContract.methods.freeMint(amount).send({
        from: address
      });

      return result;
    };

    const totalSupply = async () => {
      const result = await rawMintContract.methods.totalSupply().call();

      return +result;
    };

    const price = async () => {
      const price = await rawMintContract.methods.price().call();

      return +web3.utils.fromWei(price, 'ether');;
    };

    const claimAirdrop = () => {
      return rawMintContract.methods.claimAirdrop().call();
    };

    return {saleMint, price, freeMint, totalSupply, claimAirdrop};
  }
}
