<div class="mint-curve-bg"></div>
<div class="mint-stars-bg"></div>
<div class="mobile-apes-bg"></div>
<div class="mint-ape-bg"></div>

<div class="mint-controls">
  <!-- <h3>Mint a</h3> -->
  <h1>DOODBEAST</h1>
  <h3>
    {{totalSupply}} / 5,555 Doodbeasts Minted
  </h3>
  <h3>
    Price: 0.015 ETH each <br/>
    <!-- Max mint 15 -->
  </h3>

  <ng-container class="mint-button" *ngIf="walletConnected">
    <ng-container *ngIf="soldOut">
      <div class="mint-button sold-out">
        Sold Out
      </div>
    </ng-container>

    <ng-container *ngIf="!soldOut">

      <div class="mint-button">

        <div class="mint-input">
          {{mintAmount}}

          <svg class="arrow-up" (click)="increaseMint()">
            <use xlink:href="../../../assets/icons/basic/_sprite.svg#arrow-right"></use>
          </svg>

          <svg class="arrow-down" (click)="decreaseMint()">
            <use xlink:href="../../../assets/icons/basic/_sprite.svg#arrow-right"></use>
          </svg>
        </div>

        <ng-container *ngIf="!loading">
          <button *ngIf="!freeMintAvailable" class="mint-clickable" [disabled]="disableMintButton" (click)="mint()">
            Mint
          </button>
          <button *ngIf="freeMintAvailable" class="mint-clickable free-mint" (click)="mintFree()">
            Mint {{mintAmount}} Free
          </button>
        </ng-container>

        <button *ngIf="loading" class="mint-clickable" [disabled]="true">
          <div class="lds-facebook"><div></div><div></div><div></div></div>
        </button>
      </div>

    </ng-container>

  </ng-container>

  <button *ngIf="!walletConnected" class="mint-button" (click)="connectWallet()">
    Sold Out!
  </button>
</div>
